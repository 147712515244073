<!--
* @description:
* @fileName shopManage.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <div>
    <el-card>
      <el-form ref="formRef" label-width="120px">
        <el-form-item label="核销二维码">
          <el-image :src="qrCodeUrl"></el-image>
        </el-form-item>

        <el-form-item label="核销链接">
          <el-input v-model="linkUrl" readonly style="width: 200px"></el-input>
          <el-button
            type="primary"
            style="margin-left: 10px"
            class="copy_btn"
            :data-clipboard-text="linkUrl"
            @click="copy"
          >
            复制链接
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card>
      <vab-query-form>
        <vab-query-form-left-panel :span="12">
          核销人员列表
        </vab-query-form-left-panel>
      </vab-query-form>

      <el-table border :data="linkManagerList">
        <el-table-column label="用户名称" prop="name" show-overflow-tooltip />
        <el-table-column label="联系电话" prop="phone" show-overflow-tooltip />
        <el-table-column label="操作" show-overflow-tooltip width="120">
          <template #default="{ row }">
            <el-button type="danger" @click="handleDeleteLinkManager(row)">
              <vab-icon icon="delete-bin-6-line" />
              删除
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
    </el-card>

    <el-card>
      <vab-query-form>
        <vab-query-form-left-panel :span="12">
          核销列表
        </vab-query-form-left-panel>
      </vab-query-form>

      <el-table border :data="list">
        <el-table-column
          label="优惠券名称"
          prop="couponName"
          show-overflow-tooltip
        />
        <el-table-column label="订单号" prop="orderSn" show-overflow-tooltip />
        <el-table-column label="用户" prop="memberName" show-overflow-tooltip />

        <el-table-column label="操作时间" prop="date" show-overflow-tooltip />
        <el-table-column label="操作人" prop="operator" show-overflow-tooltip />
        <template #empty>
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>

      <el-pagination
        background
        :current-page="queryForm.pageNumber"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-card>

    <!-- <edit ref="editRef" @fetch-data="fetchData" /> -->

    <el-dialog v-model="dialogVisible" title="链接地址" width="30%">
      <div v-if="seeLink">
        <div>
          <div>二维码图片</div>
          <el-image :src="qrCodeUrl"></el-image>
        </div>

        <div>
          <div>链接地址</div>
          <div>{{ linkUrl }}</div>
        </div>
      </div>

      <div v-else></div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import Clipboard from 'clipboard'
  import {
    getShopList,
    deleteShop,
    getCheckList,
    linkByBindPhone,
    linkByBindPhoneList,
    deletelink,
  } from '@/api/shop'
  export default defineComponent({
    name: 'shopCode',
    components: {},
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: { pageNumber: 1, pageSize: 20 },
        total: 0,
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
        dialogVisible: false,
        qrCodeUrl: '',
        linkUrl: '',
        linkManagerList: [],
        seeLink: true,
        editShopId: '',
        shopId: '',
      })

      const handleDelete = (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteShop(row.id)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      onMounted(() => {
        state.shopId = proxy.$route.params.id
        console.log(state.shopId)
        handleLinkByBindPhone(state.shopId)
        queryLinkList(state.shopId)
        fetchData(state.shopId)
      })

      const handleLinkByBindPhone = async (row) => {
        // console.log(row)
        let { data } = await linkByBindPhone(row)
        state.qrCodeUrl = data.qrCodeUrl
        state.linkUrl = data.url
      }

      const handleLinkByBindPhoneList = async (row) => {
        queryLinkList(row.id)
        state.editShopId = row.id
        state.dialogVisible = true
        state.seeLink = false
      }

      const queryLinkList = async (id) => {
        let { data } = await linkByBindPhoneList(id)
        state.linkManagerList = data
      }

      const handleDeleteLinkManager = async (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            let { data } = await deletelink(row.id)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            queryLinkList(state.editShopId)
          })
        }
      }

      const fetchData = async (id) => {
        state.listLoading = true
        state.queryForm.shopId = id
        const { data } = await getCheckList(state.queryForm)
        state.list = data.list
        state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        fetchData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNo = val
        fetchData()
      }

      const copy = () => {
        var clipboard = new Clipboard('.copy_btn')
        clipboard.on('success', (e) => {
          proxy.$baseMessage(`复制成功`, 'success', 'vab-hey-message-success')
          // 释放内存
          clipboard.destroy()
        })

        clipboard.on('error', (e) => {
          // 不支持复制
          proxy.$baseMessage(`复制失败`, 'success', 'vab-hey-message-success')
          // 释放内存
          clipboard.destroy()
        })
      }

      return {
        ...toRefs(state),
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        Delete,
        Search,
        Plus,
        handleLinkByBindPhone,
        handleLinkByBindPhoneList,
        handleDeleteLinkManager,
        copy,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
